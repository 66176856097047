.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card-text-title {
  color: white;
  font-size: 15px;
  font-weight: 500;
}
.card-text-body {
  font-size: 14px;
  font-weight: 600;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-table-thead .ant-table-cell {
  background-color: green;
}

.blink {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.8;
  }
  20% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.load-content {
  width: 100%;
  height: 100%;
  animation: fade-in 4s;
}

.load-sidebar {
  animation: fade-in 2s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.glow-on-hover {
  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  color: #702a2a;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  font-size: large;
  font-weight: 600;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #dafffb;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.add-button {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  /* letter-spacing: 4px; */
  position: relative;
  background-color: #04364a;
  border: none;
  color: #fff;
  padding: 10px;
  width: auto;
  height: auto;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  font-weight: 600;
  box-shadow: 0 5px 15px #ffffff;
  border-radius: 6px;
}

.add-button:hover {
  background: #fff;
  box-shadow: 0px 2px 10px 5px #b9bc1a;
  color: #000;
}

.add-button:after {
  content: "";
  background: #91bc1a;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.add-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.add-button:focus {
  outline: 0;
}
.add-button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: all !important;
}

/* FORM BUTTON */
.save-button {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  /* letter-spacing: 4px; */
  position: relative;
  background-color: #04364a;
  border: none;
  color: #fff;
  padding: 10px;
  width: auto;
  height: auto;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  font-weight: 600;
  box-shadow: 0 5px 15px #64ccc5;
  border-radius: 6px;
}

.save-button:hover {
  background: #64ccc5;
  box-shadow: 0px 2px 10px 5px #04364a;
  color: #000;
}

.save-button:after {
  content: "";
  background: #91bc1a;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.save-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.save-button:focus {
  outline: 0;
}

.cancel-button {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  /* letter-spacing: 4px; */
  position: relative;
  background-color: #ffffff;
  border: 1px red;
  color: #000000;
  padding: 10px;
  width: auto;
  height: auto;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  font-weight: 600;
  box-shadow: 0 5px 15px #ff6b6b;
  border-radius: 6px;
}

.cancel-button:hover {
  background: #ff6b6b;
  box-shadow: 0px 2px 10px 5px #e00202;
  color: #000;
}

.cancel-button:after {
  content: "";
  background: #91bc1a;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.cancel-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.cancel-button:focus {
  outline: 0;
}

/* CSS */
.edit-button {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(0, 37, 245);
  background: #f1f1f1;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: 600;
}

.edit-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #020024,
    #06044d,
    #080768,
    #090979,
    #063e9c,
    #0559ae,
    #0470bd,
    #038fd2,
    #02ade6
  );

  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: glowing-edit-button 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.edit-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.delete-button {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(245, 0, 0);
  background: #f1f1f1;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.delete-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #240100,
    #4d1304,
    #681207,
    #790921,
    #9c0606,
    #ae0538,
    #bd4804,
    #d29b03,
    #e67002
  );

  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: glowing-edit-button 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.delete-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.detail-button {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  background: #f1f1f1;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.detail-button:before {
  content: "";
  background: linear-gradient(
    90deg,
    #0b099d,
    #0396d6,
    #073e9c,
    #0570bd,
    #00d4ff
  );

  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: glowing-edit-button 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.detail-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.detail-button:hover {
  /* background: #050c0e; */
  color: #00ffc8;
}

@keyframes glowing-edit-button {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.logout-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(150, 12, 12);
}
/* plus sign */
.logout-sign {
  width: 100%;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-sign svg {
  width: 17px;
}

.logout-sign svg path {
  fill: white;
}
/* text */
.logout-text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: 0.3s;
}

.logout-button:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: 0.3s;
}

.logout-button:hover .logout-sign {
  width: 30%;
  transition-duration: 0.3s;
  padding-left: 20px;
}

/* hover effect button's text */
.logout-button:hover .logout-text {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s;
  padding-right: 10px;
}
/* button click effect*/
.logout-button:active {
  transform: translate(2px, 2px);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --v1: calc(max(9vw, 11vh));
}
/* body {
  background: #1d0027;
  overflow: hidden;
  min-height: 200vh;
} */
.container-hex {
  position: fixed;
  height: 100vh;
  overflow: hidden;
}
.row-hex {
  display: inline-flex;
  margin-top: calc(var(--v1) * -0.32);
  margin-left: calc(var(--v1) * -0.5);
}
button.hexagon {
  border: none;
  cursor: pointer;
}

.hexagon {
  position: relative;
  width: var(--v1);
  height: calc(var(--v1) * 1.1);
  margin: calc(var(--v1) * 0.04) calc(var(--v1) * 0.02);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background: linear-gradient(
    90deg,
    rgba(25, 25, 25, 0.7) 50%,
    rgba(10, 10, 10, 0.85) 50%
  );
  background: #2c093a;
  /* optional short text */
  text-align: center;
  color: rgba(15, 15, 15, 1);
  line-height: calc(var(--v1) * 1.1);
  font-size: 2vw;
  transition: 1s;
}
.blank {
  background: transparent;
}
button.hexagon {
  background: #2c093aa9;
}
button.hexagon:hover {
  color: rgba(25, 25, 25, 1);
  background: #2c093a40;
}
.row-hex:nth-child(even) {
  margin-left: calc(var(--v1) * 0.02);
}
.curser {
  position: absolute;
  width: calc(var(--v1) * 2.5);
  height: calc(var(--v1) * 2.5);
  border-radius: 50%;
  animation: anim 2s linear infinite;
  visibility: hidden;
  /*     transition: .1s; */
  transform: translate(-50%, -50%);
  /*     box-shadow: 0px 0px 100px rgba(0,255,0,1); */
}
@keyframes anim {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
body:hover .curser {
  background: radial-gradient(
    circle,
    rgba(0, 255, 0, 1) 0%,
    rgba(0, 255, 0, 0) 70%
  );
  visibility: visible;
}
/*
60 orange
120 green
210
260
30
*/
.footer-text {
  position: relative;
  /* -webkit-animation: moving 10s infinite;
  animation: moving 10s infinite; */
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 16px;
  font-weight: 600;
}

@-webkit-keyframes moving {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

@keyframes moving {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
@-webkit-keyframes moving-backward {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@keyframes moving-backward {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@font-face {
  font-family: "Spicy Rice";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/spicyrice/v25/uK_24rSEd-Uqwk4jY1RyKvi8Ww.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA133VKmoY8A.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto Mono";

  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPq_ROW4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t3NeCAAFOvV9SNjrwlNwjM6W3Y6K5eMxd-laLZxxlE8uHw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
